import React, { forwardRef, useMemo } from 'react'
import { User, Organization, Team, Project } from '@prisma/client'
import { PencilIcon } from '@heroicons/react/24/solid'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

const colorAvatarMap = {
  A: '#DF0B92',
  B: '#650ACC',
  C: '#097FAA',
  D: '#AD6200',
  E: '#BF16F3',
  F: '#084391',
  G: '#078836',
  H: '#DF0B92',
  I: '#650ACC',
  J: '#097FAA',
  K: '#AD6200',
  L: '#BF16F3',
  M: '#084391',
  N: '#078836',
  O: '#DF0B92',
  P: '#650ACC',
  Q: '#097FAA',
  R: '#AD6200',
  S: '#BF16F3',
  T: '#084391',
  U: '#078836',
  V: '#DF0B92',
  W: '#650ACC',
  X: '#097FAA',
  Y: '#AD6200',
  Z: '#BF16F3',
  '-': '#084391',
}

import {
  AvatarIcon,
  useAvatar,
  AvatarProps as BaseAvatarProps,
} from '@heroui/avatar'

export interface AvatarProps extends BaseAvatarProps {
  onEdit?: () => void
  className?: string
  user?: User
  size?: 'sm' | 'md' | 'lg'
  project?: Project
  team?: Team
  src?: string
  organization?: Organization
  tooltip?: string
}

export const Avatar = forwardRef<HTMLSpanElement, AvatarProps>((props, ref) => {
  const obj = { src: props.src, name: props?.name?.slice(0, 2)?.toUpperCase() }
  if (props.organization) {
    obj.src = props.organization.image
    obj.name = props.organization.name.slice(0, 2).toUpperCase()
  }
  if (props.user) {
    obj.src = props.user?.image || ''
    obj.name = props.user?.firstName?.slice(0, 2)?.toUpperCase()
  }
  if (props.project) {
    obj.src = props.project.image
    obj.name = props.project.name.slice(0, 2).toUpperCase()
  }
  if (props.team) {
    obj.src = props.team.image
    obj.name = props.team.name.slice(0, 2).toUpperCase()
  }

  const updatedProps = {
    ...props,
    ...obj,
  }
  const {
    src,
    icon = <AvatarIcon />,
    alt,
    classNames,
    slots,
    name,
    showFallback,
    fallback: fallbackComponent,
    getInitials,
    getAvatarProps,
    getImageProps,
  } = useAvatar({
    ref,
    ...updatedProps,
  })

  const handleEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    const onEdit = props.onEdit
    onEdit && onEdit()
  }

  const fallback = useMemo(() => {
    if (!showFallback && src) return null

    const ariaLabel = alt || name || 'avatar'

    if (fallbackComponent) {
      return (
        <div
          aria-label={ariaLabel}
          className={`${slots.fallback({ class: classNames?.fallback })} text-gray-950`}
          role="img"
        >
          {fallbackComponent}
        </div>
      )
    }

    const heightToFontSizeMap = {
      '6': 12,
      '8': 16,
      '10': 18,
      '12': 18,
    }
    // extract height from className using regex over h-(\d+)
    const height = props.className?.match(/(?<=^|\s)h-(\d+)/)?.[1] || '8'
    const fontSize = heightToFontSizeMap[height]
    return name ? (
      <span
        aria-label={ariaLabel}
        className={`${slots.name({ class: classNames?.name })} text-gray-950`}
        role="img"
      >
        <div style={{ fontSize }} className="text-white font-medium">
          {getInitials(name)}
        </div>
      </span>
    ) : (
      <span
        aria-label={ariaLabel}
        className={slots.icon({ class: classNames?.icon })}
        role="img"
      >
        {icon}
      </span>
    )
  }, [showFallback, src, fallbackComponent, name, classNames])

  const reallyRenderBlock = () => (
    <div {...getAvatarProps()}>
      {src && <img {...getImageProps()} alt={alt} />}
      {fallback}
    </div>
  )

  const renderBlock = () => {
    if (props.tooltip) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>{reallyRenderBlock()}</TooltipTrigger>
            <TooltipContent>{props.tooltip}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    }
    return reallyRenderBlock()
  }
  if (props.onEdit) {
    return (
      <div
        className={`relative inline-block group hover:cursor-pointer ${
          props.className || ''
        }`}
        onClick={handleEdit}
      >
        {renderBlock()}
        <div className="absolute inset-0 bg-gray-800 bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 rounded-full" />
        <div className="absolute bottom-0 right-0 bg-gray-400 rounded-full p-0.5 sm:p-1 opacity-100 transition-opacity duration-300">
          <PencilIcon className="w-3 h-3" color="white" />
        </div>
      </div>
    )
  }
  return renderBlock()
})

Avatar.displayName = 'MyAvatar'
