import React from 'react'
import { Button } from '@/components/ui/button'
import { FolderOpenIcon } from '@heroicons/react/20/solid'

type Props = {
  onCreateProject: () => void // Define the type for the callback prop
}

export default function NoProjects({ onCreateProject }: Props) {
  return (
    <div className="flex items-center justify-center py-20">
      <div className="w-full max-w-md text-center">
        <div className="flex justify-center mb-4">
          <FolderOpenIcon className="h-12 w-12 text-muted-foreground" />
        </div>
        <h2 className="text-2xl font-bold mb-2">No Projects found</h2>
        <p className="text-muted-foreground mb-4">
          A Project is a collection of events and errors.
        </p>
        <Button onClick={onCreateProject}>Create New Project</Button>{' '}
        {/* Add onClick event */}
      </div>
    </div>
  )
}
