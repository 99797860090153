import React, { useEffect, useState } from 'react'
import { useQueryTC } from './react-query'
import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from '@tanstack/react-query'

import { Organization, Project } from '@prisma/client'

export function useOrganizations(): {
  organizations: Organization[]
} & UseQueryResult<any, any> {
  const qOrganizations = useQueryTC<any, any>(`organizations`)
  const [organizations, setOrganizations] = useState<any>()
  useEffect(() => {
    if (qOrganizations.data?.organizations) {
      setOrganizations(qOrganizations.data?.organizations)
    }
  }, [qOrganizations.data])
  return { organizations, ...qOrganizations }
}

export function useOrganization(organizationSlug: string): {
  organization: Organization
} & UseQueryResult<any, any> {
  const qOrganization = useQueryTC<any, any>(`organization`, null, true, {
    organizationSlug,
  })
  const [organization, setOrganization] = useState<any>()
  useEffect(() => {
    if (qOrganization.data?.organization) {
      setOrganization(qOrganization.data?.organization)
    }
  }, [qOrganization.data])
  return { organization, ...qOrganization }
}

export function useProject(projectSlug: string): {
  organization: Organization
  project: Project
} {
  const qProject = useQueryTC<any, any>(`project`, null, true, {
    projectSlug,
  })
  const [organization, setOrganization] = useState<any>()
  const [project, setProject] = useState<any>()
  useEffect(() => {
    if (qProject.data?.project && qProject.data?.organization) {
      setProject(qProject.data?.project)
      setOrganization(qProject.data?.organization)
    }
  }, [qProject.data])
  return { organization, project }
}
